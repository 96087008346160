<template>
  <div class>
    <Navigation />

    <div class="wrap-box">
      <h3 class="heading">{{ newsItem.title }}</h3>
    </div>

    <div class="wrap-box">
      <div class="news-box">
        <div class="news-item-image" :style="`background-image: url('${newsItem.image}')`"></div>
        <span class="news-item-content" v-html="newsItem.content"></span>
        <div class="news-item-data">
          <span @click.prevent="redirectToNews" class="news-link-back">Повернутися назад</span>
          <div class="news-item-date">{{ dateToShow }}</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Navigation from "../components/Navigation";

export default {
  name: "NewsItem",
  components: {
    Navigation
  },
  data: () => ({
    newsItem: {
      title: "",
      image: "",
      content: "",
      date: ""

      // title: "Відкрито новий відділ » Дитяче здоров’я»!",
      // image:
      //   "https://rada.info/upload/users_files/38960518/76be7f75c2333c7bd287a5557e76573b.jpeg",
      // content: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Arcu risus quis varius quam quisque id. Habitant morbi tristique senectus et netus et malesuada fames. Lobortis scelerisque fermentum dui faucibus in. A cras semper auctor neque vitae tempus quam pellentesque. Sit amet risus nullam eget felis eget nunc. Est ante in nibh mauris cursus mattis molestie a iaculis. Ut ornare lectus sit amet est placerat. Sollicitudin ac orci phasellus egestas tellus rutrum. Et tortor consequat id porta.

      // Est ante in nibh mauris cursus mattis. Faucibus a pellentesque sit amet porttitor eget dolor morbi. Arcu dui vivamus arcu felis bibendum ut tristique. Magna eget est lorem ipsum dolor sit amet consectetur. Quis varius quam quisque id diam. Varius sit amet mattis vulputate enim. Vestibulum sed arcu non odio euismod lacinia at quis. Aliquet lectus proin nibh nisl condimentum id venenatis a condimentum. Ornare aenean euismod elementum nisi quis. Eget felis eget nunc lobortis mattis. Donec ac odio tempor orci dapibus ultrices in.

      // Orci nulla pellentesque dignissim enim sit amet venenatis urna cursus. Neque laoreet suspendisse interdum consectetur libero id faucibus. Convallis a cras semper auctor neque. Ultricies integer quis auctor elit sed vulputate mi sit. A condimentum vitae sapien pellentesque habitant. Odio ut sem nulla pharetra diam sit amet nisl. Quis viverra nibh cras pulvinar mattis nunc sed. Hac habitasse platea dictumst quisque. Amet volutpat consequat mauris nunc congue nisi vitae suscipit tellus. Sit amet mattis vulputate enim nulla aliquet porttitor lacus. Massa tempor nec feugiat nisl pretium fusce id velit. Lectus nulla at volutpat diam ut venenatis tellus. In fermentum et sollicitudin ac orci phasellus egestas. Nunc sed augue lacus viverra.

      // Faucibus turpis in eu mi bibendum neque egestas. Arcu bibendum at varius vel pharetra vel. Quisque id diam vel quam elementum pulvinar etiam non quam. Volutpat odio facilisis mauris sit amet massa. Tristique sollicitudin nibh sit amet commodo nulla facilisi. Elit sed vulputate mi sit amet mauris commodo quis. Massa enim nec dui nunc. Faucibus a pellentesque sit amet porttitor. Nisl nisi scelerisque eu ultrices vitae. Porta lorem mollis aliquam ut porttitor leo. A lacus vestibulum sed arcu non odio euismod. Neque volutpat ac tincidunt vitae semper. Tristique et egestas quis ipsum. Enim nulla aliquet porttitor lacus luctus. Curabitur vitae nunc sed velit dignissim sodales ut eu sem. Nisl nunc mi ipsum faucibus. Consequat nisl vel pretium lectus quam. At varius vel pharetra vel turpis nunc. Lectus proin nibh nisl condimentum.`,
      // date: "2021-07-03T20:14:33.481Z"
    }
  }),
  async created() {
    const newsSlug = this.$route.params.id;
    await this.loadNewsItem(newsSlug);
  },
  methods: {
    async loadNewsItem(slug) {
      const json = await fetch(`/api/v1/news/?slug=${slug}`);
      const { data } = await json.json();

      if (!data.length) {
        return this.$router.replace("/404");
      }
      this.newsItem = data[0];
    },
    redirectToNews() {
      window.scrollTo(0, 0);
      this.$router.push("/news");
    }
  },
  computed: {
    dateToShow() {
      if (this.newsItem.date == "") return;
      let formatter = new Intl.DateTimeFormat("ru", {
        year: "numeric",
        month: "numeric",
        day: "numeric"
      });

      const date = new Date(this.newsItem.date);
      return formatter.format(date);
    }
  }
};
</script>
<style scoped>
@import "~vue2-editor/dist/vue2-editor.css";
@import "~quill/dist/quill.core.css";
@import "~quill/dist/quill.bubble.css";
@import "~quill/dist/quill.snow.css";

.wrap-box {
  max-width: 1250px;
  width: calc(100% - 30px);
  margin: 0 auto 30px auto;
  padding: 1px 0;
}

h3.heading {
  color: #4e5154;
  margin: 30px auto 30px 40px;
  font-size: 34px;
  text-align: left;
}

h3.heading::after {
  content: " ";
  display: block;
  width: 100%;
  max-width: 200px;
  height: 3px;
  top: 100%;
  left: 0;
  background: #aec3de;
}

.news-box {
  max-width: 1000px;
  /* width: calc(100% - 30px); */
  margin: 0 auto 30px 10px;
  /* min-height: 60vh; */
  box-shadow: 3px 5px 8px 0 rgba(0, 0, 0, 0.35),
    5px 8px 15px 0 rgba(0, 0, 0, 0.2);
  background-color: #e3e3e3;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.news-item-image {
  max-width: 800px;
  margin: 20px auto;
  width: calc(100% - 40px);
  height: 320px;
  background-position: center;
  background-size: cover;
  box-shadow: 3px 5px 8px 0 rgba(0, 0, 0, 0.35),
    5px 8px 15px 0 rgba(0, 0, 0, 0.2);
}

.news-item-content {
  margin: 20px auto;
  height: auto;
  width: calc(100% - 40px);
  display: block;
  list-style: disc;
  font-size: initial;
  padding: initial;
  text-decoration: initial;
  font-weight: initial;
  text-align: justify;
  background: auto;
}

.news-item-data {
  width: calc(100% - 40px);
  display: flex;
}

.news-link-back {
  font-size: 18px;
  color: #294ccc;
  cursor: pointer;
}

.news-item-date {
  align-self: flex-end;
  margin: 10px 40px 20px auto;
  font-size: 18px;
  font-weight: bold;
  color: #4e5154;
}
</style>
